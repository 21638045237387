import React, { useEffect, useState } from 'react';
import { AlsFallback } from '@alcs/core/components/containers/AlsFallback';
import { usePasswordChangeI18n } from '@alcs/core/utils/PasswordUtils';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import { useRouter } from 'next/router';

import { CompleteRegistration } from '../components/CompleteRegistration';
import { PageContainer } from '../components/PageContainer';
import { PasswordReset } from '../components/PasswordReset';
import { createTransporter } from '../SessionStorage/useTransporter';

export const completedRegistrationTransporter = createTransporter<boolean>('completedRegistration');
export const registrationErrorTransporter = createTransporter<string>('registrationError');
export const passwordResetErrorTransporter = createTransporter<string>('passwordResetError');

export type PasswordChangeComponentProps = { accessKey: string };

const SELF_REG_PARAM = 'key';
const TEMP_KEY_PARAM = 'tempKey';

enum PasswordChangeType {
    SELF_REGISTRATION,
    PASSWORD_RESET,
}

const Wrapper = styled('div', {
    label: 'ChangePasswordPageWrapper',
})({
    width: 295,
});

const ChangePasswordPage = () => {
    const [passwordChangeType, setPasswordChangeType] = useState<PasswordChangeType>();
    const [accessKey, setAccessKey] = useState<string>();
    const router = useRouter();
    const i18n = usePasswordChangeI18n();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get(SELF_REG_PARAM)) {
            setPasswordChangeType(PasswordChangeType.SELF_REGISTRATION);
            setAccessKey(searchParams.get(SELF_REG_PARAM)!);
        } else if (searchParams.get(TEMP_KEY_PARAM)) {
            setPasswordChangeType(PasswordChangeType.PASSWORD_RESET);
            setAccessKey(searchParams.get(TEMP_KEY_PARAM)!);
        } else {
            router.replace('/alcs/Login.html');
        }
    }, [router]);

    if (isNil(passwordChangeType) || !accessKey) {
        return <AlsFallback />;
    }

    const PasswordChangeComponent: React.FunctionComponent<PasswordChangeComponentProps> =
        passwordChangeType === PasswordChangeType.PASSWORD_RESET ? PasswordReset : CompleteRegistration;

    return (
        <PageContainer title={i18n.changePassword} backHref="/alcs/Login.html" backTitle={i18n.backToSignIn}>
            <Wrapper>
                <PasswordChangeComponent accessKey={accessKey} />
            </Wrapper>
        </PageContainer>
    );
};

export default ChangePasswordPage;
