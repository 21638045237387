import { useCallback } from 'react';
import { createDefaultI18n, useI18n } from '@alcs/i18n';

import { insertParams } from './TextUtils';

export const MIN_PASS_LENGTH = 8;

const passwordI18n = createDefaultI18n('components.ChangePassword', {
    backToSignIn: 'Back to sign in',
    signIn: 'Sign In',
    changePassword: 'Change password',
    username: 'Username (e-mail)',
    currentPassword: 'Current password',
    newPassword: 'New password',
    newPasswordRepeat: 'New password (repeat)',
    mismatchError: "New passwords doesn't match",
    resetKeyInvalidError:
        'Unfortunately, the key is invalid or already expired, please check key or try to change password again',
    strengthError:
        'Password should be at least :p0 characters long, and should contain at least one digit. New password you are trying to set is too weak.',
    successfullyCompletedRegistrationMessage:
        'You have successfully completed registration! Please change your password. New password should be at least :p0 characters long, and should containt at least one digit.',
    passwordChangeSuccess: 'Password changed successfully',
});

const insertMinPassLength = (text: string): string => insertParams(text, [MIN_PASS_LENGTH.toString()]);

export const usePasswordChangeI18n = () => {
    const i18n = useI18n(passwordI18n);
    i18n.successfullyCompletedRegistrationMessage = insertMinPassLength(i18n.successfullyCompletedRegistrationMessage);
    i18n.strengthError = insertMinPassLength(i18n.strengthError);

    return i18n;
};

const hasNumber = (value: string) => /\d/.test(value);

export const usePasswordStrengthValidator = () => {
    const i18n = usePasswordChangeI18n();

    const validatePasswordStrength = useCallback(
        (password: string) => {
            if (password.length > 0 && (password.length < MIN_PASS_LENGTH || !hasNumber(password))) {
                return i18n.strengthError;
            }

            return null;
        },
        [i18n.strengthError],
    );
    return validatePasswordStrength;
};
