import React, { useCallback, useEffect, useState } from 'react';
import { AlsFallback } from '@alcs/core/components/containers/AlsFallback';
import { usePasswordChangeI18n } from '@alcs/core/utils/PasswordUtils';
import { useService } from '@alcs/core/utils/useService';
import { useSnackbar } from '@alcs/popups';
import { Als_usr, emptyServiceCallback } from '@alcs/services';
import { FormHelpers } from '@reactive-forms/core';
import { AxiosError } from 'axios';

import { PasswordChangeForm, PasswordChangeValues } from './PasswordChangeForm';
import { PasswordChangeComponentProps, passwordResetErrorTransporter } from '../pages/change-password';
import { useSender } from '../SessionStorage/useTransporter';
import { useLogin } from '../utils/useLogin';

export const PasswordReset = ({ accessKey }: PasswordChangeComponentProps) => {
    const alsUsrService = useService(Als_usr, emptyServiceCallback);
    const [isKeyValid, setIsKeyValid] = useState(false);
    const sendError = useSender(passwordResetErrorTransporter);
    const login = useLogin();
    const i18n = usePasswordChangeI18n();
    const showSnackbar = useSnackbar();

    useEffect(() => {
        const checkKeyValidity = async () => {
            try {
                const isValidKey = await alsUsrService.is_key_valid(accessKey);
                if (isValidKey) {
                    setIsKeyValid(true);
                } else {
                    sendError(i18n.resetKeyInvalidError, '/alcs/Login.html');
                }
            } catch {
                sendError(i18n.resetKeyInvalidError, '/alcs/Login.html');
            }
        };
        checkKeyValidity();
    }, [accessKey, alsUsrService, i18n.resetKeyInvalidError, sendError]);

    const passwordChangeHandler = useCallback(
        async (
            { newPassword, newPasswordRepeated }: PasswordChangeValues,
            { setFieldError, paths }: FormHelpers<PasswordChangeValues>,
        ) => {
            try {
                const { email } = await alsUsrService.set_new_password_by_key(
                    accessKey,
                    newPassword,
                    newPasswordRepeated,
                );
                await login(email, newPassword, '', '', false);
            } catch (error) {
                const message = (error as AxiosError).response?.data.messages[0];
                switch (message.code) {
                    case 'error.passwords.mismatch':
                        return setFieldError(paths.newPasswordRepeated, {
                            $error: message.default_text,
                        });
                    case 'error.pwd.strength':
                        return setFieldError(paths.newPassword, {
                            $error: message.default_text,
                        });
                    default:
                        return showSnackbar(message.default_text, 'error');
                }
            }
        },
        [accessKey, alsUsrService, login, showSnackbar],
    );

    if (!isKeyValid) {
        return <AlsFallback />;
    }

    return <PasswordChangeForm submitHandler={passwordChangeHandler} />;
};
