import React, { useCallback, useEffect, useState } from 'react';
import { AlsFallback } from '@alcs/core/components/containers/AlsFallback';
import { usePasswordChangeI18n } from '@alcs/core/utils/PasswordUtils';
import { useLanguages } from '@alcs/core/utils/useLanguages';
import { useService } from '@alcs/core/utils/useService';
import { useSnackbar } from '@alcs/popups';
import { emptyServiceCallback, RegistrationService } from '@alcs/services';
import { Alert, Icon, styled, Typography } from '@mui/material';
import { FormHelpers } from '@reactive-forms/core';
import { AxiosError } from 'axios';

import { useLanguage } from './I18nResourceLoader';
import { PasswordChangeForm, PasswordChangeValues } from './PasswordChangeForm';
import {
    completedRegistrationTransporter,
    PasswordChangeComponentProps,
    registrationErrorTransporter,
} from '../pages/change-password';
import { useSender } from '../SessionStorage/useTransporter';
import { useLogin } from '../utils/useLogin';

const LANGUAGE_PARAM = 'lang';

const Wrapper = styled('div', {
    label: 'CompleteRegistrationWrapper',
})({
    display: 'grid',
    rowGap: 10,
});

const UsernameWrapper = styled('div', {
    label: 'CompleteRegistrationUsername',
})({
    display: 'flex',
    gap: 10,
});

export const CompleteRegistration = ({ accessKey }: PasswordChangeComponentProps) => {
    const registrationService = useService(RegistrationService, emptyServiceCallback);
    const sendCompleted = useSender(completedRegistrationTransporter);
    const sendError = useSender(registrationErrorTransporter);
    const [userName, setUsername] = useState<string>();
    const [tempPassword, setTempPassword] = useState<string>();
    const login = useLogin();
    const i18n = usePasswordChangeI18n();

    const [, setLanguage] = useLanguage();
    const { data: languages } = useLanguages();
    const showSnackbar = useSnackbar();

    useEffect(() => {
        const language = new URLSearchParams(window.location.search).get(LANGUAGE_PARAM)?.toLocaleUpperCase();
        if (language && languages) {
            const loadedLanguage = languages.find(lang => lang.value_code === language);
            loadedLanguage &&
                setLanguage(current => (current.value_code !== loadedLanguage.value_code ? loadedLanguage : current));
        }
    }, [languages, setLanguage]);

    useEffect(() => {
        const load = async () => {
            try {
                const regResult = await registrationService.complete_self_registration(accessKey);
                if (regResult.tempPassword) {
                    setTempPassword(regResult.tempPassword);
                    setUsername(regResult.username);
                } else {
                    sendCompleted(true, '/alcs/Login.html');
                }
            } catch (error) {
                sendError((error as AxiosError).response?.data.messages[0]?.default_text, '/alcs/Login.html');
            }
        };
        load();
    }, [accessKey, registrationService, sendCompleted, sendError]);

    const passwordChangeHandler = useCallback(
        async (
            { newPassword, newPasswordRepeated }: PasswordChangeValues,
            { setFieldError, paths }: FormHelpers<PasswordChangeValues>,
        ) => {
            try {
                await login(userName!, tempPassword!, newPassword, newPasswordRepeated, false);
            } catch (error) {
                const message = (error as AxiosError).response?.data.messages[0];

                switch (message.code) {
                    case 'error.passwords.mismatch':
                        return setFieldError(paths.newPasswordRepeated, {
                            $error: message.default_text,
                        });
                    case 'error.pwd.strength':
                        return setFieldError(paths.newPassword, {
                            $error: message.default_text,
                        });
                    default:
                        return showSnackbar(message.default_text, 'error');
                }
            }
        },
        [login, showSnackbar, tempPassword, userName],
    );

    if (!userName || !tempPassword) {
        return <AlsFallback />;
    }

    return (
        <Wrapper>
            <Alert severity="success">{i18n.successfullyCompletedRegistrationMessage}</Alert>
            <UsernameWrapper>
                <Icon color="action">person_rounded_icon</Icon>
                <Typography>{userName}</Typography>
            </UsernameWrapper>
            <PasswordChangeForm submitHandler={passwordChangeHandler} />
        </Wrapper>
    );
};
