import { useCallback } from 'react';
import { useService } from '@alcs/core/utils/useService';
import { emptyServiceCallback, LoginService } from '@alcs/services';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { useLanguage } from '../components/I18nResourceLoader';
import { companyIdCookieKey } from '../pages/choose-company';

export const useRedirectToMain = () => {
    const loginService = useService(LoginService, emptyServiceCallback);
    const router = useRouter();
    const [language] = useLanguage();

    const redirectToMain = useCallback(
        async (comId: number, redirectUrl: string) => {
            Cookies.set(companyIdCookieKey, comId.toString(), { expires: 365 });
            await loginService.setSessionCompany(comId);
            await loginService.setSessionLanguage(language.value_code);

            sessionStorage.clear();
            router.push({
                pathname: redirectUrl,
                hash: window.location.hash,
                query: {
                    cid: comId,
                },
            });
        },
        [language, loginService, router],
    );

    return redirectToMain;
};
