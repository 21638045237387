import React from 'react';
import { AlsSubmitButton } from '@alcs/core/components/AlsSubmitButton';
import { usePasswordChangeI18n, usePasswordStrengthValidator } from '@alcs/core/utils/PasswordUtils';
import { styled } from '@mui/material';
import { FieldError, ReactiveFormProvider, SubmitAction, useForm } from '@reactive-forms/core';

import { PasswordField } from './PasswordField';

export type PasswordChangeValues = {
    newPassword: string;
    newPasswordRepeated: string;
};

const validateForm = (values: PasswordChangeValues, misMatchError: string): FieldError<PasswordChangeValues> | null => {
    if (values.newPassword !== values.newPasswordRepeated) {
        return {
            newPasswordRepeated: {
                $error: misMatchError,
            },
        };
    }

    return null;
};

const FormWrapper = styled('div', {
    label: 'PasswordChangeFormWrapper',
})({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
});

export const PasswordChangeForm = ({ submitHandler }: { submitHandler: SubmitAction<PasswordChangeValues> }) => {
    const i18n = usePasswordChangeI18n();
    const validatePasswordStrength = usePasswordStrengthValidator();

    const formBag = useForm<PasswordChangeValues>({
        initialValues: {
            newPassword: '',
            newPasswordRepeated: '',
        },
        onSubmit: submitHandler,
        validateForm: values => validateForm(values, i18n.mismatchError),
    });
    const { paths } = formBag;

    return (
        <ReactiveFormProvider formBag={formBag}>
            <FormWrapper>
                <PasswordField name={paths.newPassword} label={i18n.newPassword} validator={validatePasswordStrength} />
                <PasswordField name={paths.newPasswordRepeated} label={i18n.newPasswordRepeat} />
                <AlsSubmitButton variant="contained" color="primary" type="submit">
                    {i18n.signIn}
                </AlsSubmitButton>
            </FormWrapper>
        </ReactiveFormProvider>
    );
};
